import React from "react";

export const ArrowLeft = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20.25 12H3.75"
        strokeWidth="2"
        strokeLinecap="roundstash"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.25L3.75 12L10.5 18.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
