import axios from "axios";
import CODES from "./StatusCodes";
import { Cookies } from "react-cookie";
import { BASEURL } from "../helper/constant";
import EncryptDecrypt from "./Encryption";
// import store from "../Redux/store";
// import { logout } from "../Redux/Auth/Actions";

const METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};

const cookies = new Cookies();

class Api {
  isLoggedIn = false;
  constructor() {
    this.baseURL = BASEURL;
    this.token = cookies.get("token") || null;
    this.getAuthenticationInfo();
  }

  getAuthenticationInfo() {
    if (this.token) {
      this.isLoggedIn = true;
    }
  }

  get(url, data, isEncrypt = true, cT = {}) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data, isEncrypt, cT)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  post(url, data, isEncrypt = true, cT = {}) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data, isEncrypt, cT)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  patch(url, data, isEncrypt = true, cT = {}) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PATCH, url, data, isEncrypt, cT)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  put(url, data, isEncrypt = true, cT = {}) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PUT, url, data, isEncrypt, cT)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete(url, data, isEncrypt = true, cT = {}) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DELETE, url, data, isEncrypt, cT)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  api(method, url, data, isEncrypt, cT = {}) {
    return new Promise((resolve, reject) => {
      let axiosConfig = {};
      axiosConfig.method = method;
      axiosConfig.url = this.baseURL + url;
      axiosConfig.headers = this.setHeaders({
        ...data,
        isMultipart: !!cT?.isMultipart,
      });
      if (data)
        axiosConfig.data = isEncrypt ? EncryptDecrypt.encrypt(data) : data;

      if (cT?.cancelToken) axiosConfig.cancelToken = cT.cancelToken;

      // if (data) {
      //   if (data.params)
      //     axiosConfig.params = isEncrypt
      //       ? EncryptDecrypt.encrypt(data.params)
      //       : data.params;
      //   if (data.data)
      //     axiosConfig.data = isEncrypt
      //       ? EncryptDecrypt.encrypt(data.data)
      //       : data.data;
      // }

      axios(axiosConfig)
        .then((response) => {
          if (parseInt(response.data.status) === 0) {
            throw new Error(response.data.message);
          }
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === CODES.UNAUTHORIZED) {
              // store.dispatch(logout());
              reject(error.response.data);
            } else if (error.response.status === CODES.INTERNAL_SERVER_ERROR) {
              reject(error.response.data);
            } else {
              reject(error.response.data);
            }
          } else {
            reject(error || "Internal Server Error");
          }
        });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers["accept-language"] = "en";
    headers["Content-Type"] = "application/json";

    if (data) {
      if (data.isMultipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (data.headers) {
        for (var key in data.headers) {
          if (data.headers.hasOwnProperty(key)) {
            headers[key] = data.headers[key];
          }
        }
      }
    }
    if (this.isLoggedIn) {
      headers["Authorization"] = this.token;
    }
    headers["service_id"] = "2";
    headers["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return headers;
  }
}

export default Api;
