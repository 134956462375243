import React, { useEffect, useState } from "react";
import { MainLayout } from "../../components/layout";
import { Button } from "../../components/ui";
import { useLocation, useNavigate } from "react-router-dom";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { useSelector } from "react-redux";

const ScheduleAppointment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { userData } = useSelector((state) => state.users);
  const [displayButton, setDisplayButton] = useState(false);

  useEffect(() => {
    if (!state) {
      navigate("/counselor-slots");
    }
  }, [state]);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      setDisplayButton(true);
    },
  });

  return (
    <MainLayout>
      {displayButton && (
        <>
          <div className="text-textGray font-glacialBold text-3xl px-12 lg:px-28 text-center">
            Your appointment is confirmed. Almost done… Download the HUSK Mental
            Health app to complete your profile and intake forms before your
            first visit.
          </div>
          <div className="py-8 flex justify-center">
            <Button
              size="md"
              width={246}
              onClick={() => navigate("/appointment-confirmation")}
              className="font-glacialBold"
            >
              CONTINUE
            </Button>
          </div>
        </>
      )}
      <InlineWidget
        url={state?.scheduling_url}
        prefill={{
          email: userData?.email,
          name: userData?.firstName + " " + userData?.lastName,
        }}
        styles={{
          height: "80vh",
        }}
      />
    </MainLayout>
  );
};

export default ScheduleAppointment;
