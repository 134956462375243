import React from "react";

export const InfoIcon = ({ onClick }) => {
  return (
    <div
      className="bg-slate-400 cursor-pointer text-white text-[10px] flex justify-center items-center h-4 w-4 rounded-full"
      onClick={onClick}
    >
      ?
    </div>
  );
};
