import React from "react";
import Logo from "../../../assets/images/huskLogo.png";

export const MainLayout = ({ children }) => {
  return (
    <div className="h-screen max-h-screen flex flex-1 flex-col overflow-hidden">
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="flex py-[18px]">
          <div className="flex">
            <img
              src={Logo}
              className={"h-[80px]"}
              width={174}
              alt="Husk Logo"
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col">{children}</div>
      </div>
      <div className="bg-staticGray py-2 flex flex-col xm:flex-row justify-center items-center text-white text-xs am:text-base">
        Need help? Contact us at{" "}
        <a
          href="mailto:support@huskwellness.com"
          className="color-white underline pl-1"
        >
          support@huskwellness.com
        </a>
      </div>
    </div>
  );
};
