import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorImage from "../assets/images/error-404.png";
import { Button } from "../components/ui";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 h-screen w-screen justify-center items-center flex-col">
      <div className="w-60 h-60">
        <img alt="404" src={ErrorImage} height="100%" width={"100%"} />
      </div>
      <div className="text-textGray text-2xl my-4 font-bold">
        Page Not found
      </div>
      <Button
        variant="secondary"
        size="lg"
        onClick={() => navigate("/")}
        style={{ width: "184px" }}
      >
        Back to home
      </Button>
    </div>
  );
};

export default PageNotFound;
