import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { clearState, setUserData } from "../redux/users/reducer";
import { persistor, store } from "../redux/store";

const ProtectedRoute = ({ login, redirectPath = "/register", children }) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();

  if (!cookies.get("token")) {
    cookies.remove("token");
    dispatch(clearState());
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
