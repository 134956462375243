import { ENCRYPTKEY } from "../helper/constant";

const CryptoJS = require("crypto-js");

class EncryptDecrypt {
  static encrypt = (data, type) => {
    let encryptData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      ENCRYPTKEY
    ).toString();
    if (type === 1) {
      // 1 means get request
      return {
        params: {
          data: encryptData,
        },
      };
    }
    return { data: encryptData }; // post/put request
  };

  static decrypt = (data) => {
    let bytes = CryptoJS.AES.decrypt(data, ENCRYPTKEY);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedData;
  };
}

export default EncryptDecrypt;
