import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Suspense } from "react";
import { Loader } from "./icons";
import { protectedRoutes, publicRoutes } from "./routes";
import PageNotFound from "./routes/PageNotFound";
import Redirect from "./routes/Redirect";
import ProtectedRoute from "./routes/ProtectedRoutes";
import { history } from "./redux/store";

function App() {
  window.addEventListener("popstate", () => {
    history.go(1);
  });
  return (
    <div>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="flex flex-1 h-screen justify-center items-center">
              <Loader height={64} width={64} />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Redirect />} />
            <Route
              path="/hippa-consent"
              element={<Redirect to="/hipaa-consent" />}
            />
            {publicRoutes.map((route, i) => {
              return (
                <Route path={route.path} element={route.component} key={i} />
              );
            })}
            <Route element={<ProtectedRoute />}>
              {protectedRoutes.map((route, i) => {
                return (
                  <Route path={route.path} element={route.component} key={i} />
                );
              })}
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
