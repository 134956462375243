import { lazy } from "react";
import ScheduleAppointment from "../views/appointmentConfirmed";
import DiscoveryQueue from "../views/discoveryQueue";

const Insurance = lazy(() => import("../views/insurance"));
const Register = lazy(() => import("../views/register"));
const Slots = lazy(() => import("../views/slots"));
const CounselorList = lazy(() => import("../views/counselorList"));
const AppointmentConfirmation = lazy(() =>
  import("../views/appointmentConfirmation")
);
const Preference = lazy(() => import("../views/preference"));
const MobileAppointment = lazy(() => import("../views/mobileAppointment"));
const StaticPage = lazy(() => import("../views/staticPage"));
const ForgotPassword = lazy(() => import("../views/forgotPassword"));

export const publicRoutes = [
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/insurance",
    component: <Insurance />,
  },
  {
    path: "/book-mobile-appointment",
    component: <MobileAppointment />,
  },
  {
    path: "/terms",
    component: <StaticPage type={"terms"} />,
  },
  {
    path: "/privacy",
    component: <StaticPage type={"privacy"} />,
  },
  {
    path: "/hipaa-consent",
    component: <StaticPage type={"hippa"} />,
  },
  {
    path: "/generate-thoughts-passcode",
    component: <ForgotPassword />,
  },
  {
    path: "/payment",
    component: <StaticPage type={"payment"} />,
  },
];

export const protectedRoutes = [
  {
    path: "/preference",
    component: <Preference />,
  },
  {
    path: "/counselor-slots",
    component: <Slots />,
  },
  {
    path: "/counselor-list",
    component: <CounselorList />,
  },
  {
    path: "/appointment-confirmation",
    component: <AppointmentConfirmation />,
  },
  {
    path: "/schedule-appointment",
    component: <ScheduleAppointment />,
  },
  {
    path: "/discovery-queue",
    component: <DiscoveryQueue />,
  },
];
