import clsx from "clsx";
import React from "react";
import { Loader } from "../../../icons";

export const Button = ({
  type = "primary",
  variant = "contained",
  children,
  size = "sm",
  bgColor = "primary",
  loading = false,
  disabled = false,
  className,
  iconRight,
  iconLeft,
  width,
  ...rest
}) => {
  const RightIcon = iconRight ?? null;
  const LeftIcon = iconLeft ?? null;
  return (
    <button
      className={clsx(
        `flex w-full border rounded-xl justify-center items-center text-white font-semibold text-base cursor-pointer group`,
        variant === "contained" &&
          `bg-${bgColor} border border-${bgColor} enabled:hover:bg-transparent enabled:hover:border-${bgColor} enabled:hover:text-${bgColor}`,
        variant === "contained" &&
          type === "primary" &&
          `bg-primary border border-primary enabled:hover:bg-transparent enabled:hover:border-primary enabled:hover:text-primary`,

        variant === "secondary" &&
          `bg-textGray enabled:hover:bg-transparent enabled:hover:border-textGray enabled:hover:text-textGray`,
        variant === "outlined" &&
          `bg-transparent border border-primary text-primary enabled:hover:bg-primary enabled:hover:text-white`,
        disabled &&
          "opacity-50 text-white disabled:cursor-not-allowed hover:text-white",
        size === "sm" && "p-2",
        size === "md" && "p-3",
        size === "lg" && "p-5",
        className
      )}
      style={{
        width: width && `${width}px`,
      }}
      disabled={disabled}
      {...rest}
    >
      <div className="flex flex-1 justify-center items-center">
        {iconLeft && (
          <LeftIcon
            // className={`h-[20px] w-[20px] pr-[4px] stroke-white  group-hover:stroke-primary`}
            className={clsx(
              `h-[20px] w-[20px] pr-[4px] stroke-white group-hover:stroke-${bgColor}`,
              disabled && "group-hover:stroke-white"
            )}
          />
        )}
        <div>
          {!loading ? children : <Loader height={20} width={20} fill="#fff" />}
        </div>
        {iconRight && (
          <RightIcon
            className={clsx(
              `h-[20px] rotate-180 w-[20px] pr-[4px] stroke-white group-hover:stroke-${bgColor}`,
              disabled && "group-hover:stroke-white"
            )}
          />
        )}
      </div>
    </button>
  );
};
