export const COLORS = {
  primary: "#209092",
  nutrition: "#AECE38",
  rewardRed: "#E06226",
  movementPink: "#E48BA4",
  mentalGreen: "#209092",
  marketGray: "#EAEAEA",
  textGray: "#5A6B80",
  staticGray: " #555a60",
  white: "#fff",
  black: "#000",
  lightGray: "#D9D9D9",
  darkGray: "#54595F",
  gray50: "#f9fafb",
  slate200: "#e2e8f0",
  errorText: "#e06226",
  husk: "#F0AF42",
  light: "#f5f5f5",
  charcole:"#495057"
};
