import React from "react";
import MentalHealthImg from "../../../assets/images/mentalHealth.png";
import Logo from "../../../assets/images/huskLogo.png";

export const RegisterLayout = ({ image, children }) => {
  return (
    <div className="flex w-full flex-1 flex-col lg:flex-row lg:overflow-hidden overflow-x-auto lg:h-screen">
      <div className="md:flex flex-col lg:flex-3 flex-1">
        <div className="flex py-[6px]">
          <div className="flex pl-[20px]">
            <img src={Logo} height={64} width={124} />
          </div>
        </div>
        <div className="flex flex-1 justify-center items-center">
          <img
            src={MentalHealthImg}
            height={"100%"}
            width={"100%"}
            clsassName="object-contain"
            alt="mentalhealth"
          />
        </div>
        <div className="lg:h-[8vh] h-0"></div>
      </div>
      <div className="flex flex-col lg:flex-2 flex-1 lg:overflow-x-auto">
        {children}
      </div>
    </div>
  );
};
