import React, { useEffect, useState } from "react";
import { MainLayout } from "../../components/layout";
import clsx from "clsx";
import Api from "../../api/ApiHandler";
import EncryptDecrypt from "../../api/Encryption";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/users/reducer";
import { Loader } from "../../icons";
import { Button } from "../../components/ui";

const DiscoveryQueue = () => {
  const api = new Api();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.users);
  const [isAddingToDF, setIsAddingToDF] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!userData?.isInDiscovery) {
      addClientToDiscoveryQueue();
    }
  }, [userData?.isInDiscovery]);

  const addClientToDiscoveryQueue = async () => {
    try {
      setIsAddingToDF(true);
      const response = await api.get("/add-client-to-discovery-queue");
      const data = EncryptDecrypt.decrypt(response);
      if (data?.status) {
        dispatch(
          setUserData({
            ...userData,
            isInDiscovery: true,
          })
        );
      } else {
        setIsError(true);
      }
      setIsAddingToDF(false);
    } catch (err) {
      setIsAddingToDF(false);
      setIsError(true);
    }
  };

  return (
    <MainLayout>
      <div
        className={clsx(
          "flex flex-col flex-1 px-[12px] tb:px-[32px] pb-[40px] h-full justify-center"
        )}
      >
        {isAddingToDF ? (
          <div className="flex flex-1 items-center justify-center text-[18px] text-mentalGreen">
            <Loader height="72px" width="72px"></Loader>
          </div>
        ) : !isError ? (
          <div
            className={clsx(
              "font-glacialBold text-[24px] lg:text-[34px] text-textGray p-3 text-center  pb-[72px]"
            )}
          >
            We need more information to match you to a Mental Health Counselor.
            A member of our team will contact you within 48 hours.
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <div
              className={
                "font-glacialBold text-[24px] lg:text-[34px] text-textGray px-3 text-center"
              }
            >
              Oops! Something went wrong.
            </div>
            <Button
              width={242}
              className={"mt-2"}
              onClick={addClientToDiscoveryQueue}
            >
              Try Again
            </Button>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default DiscoveryQueue;
