import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Redirect = ({ to = "/register" }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [to]);

  return;
};

export default Redirect;
