import React from "react";
import { COLORS } from "../../../helper/colors";
import Select from "react-select";

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: !state.isDisabled ? COLORS.white : COLORS.gray50,
    boxShadow: "none",
    borderColor: COLORS.textGray, //state.isFocused && COLORS.textGray,
    outline: COLORS.textGray, //state.isFocused && COLORS.textGray,
    textAlign: "left",
    paddingBlock: 2,
    paddinInline: 4,
    borderRadius: 6,

    "&:hover": {
      borderColor: COLORS.textGray,
      outline: COLORS.textGray,
    },
  }),
  singleValue: (styles) => {
    return {
      ...styles,
      color: COLORS.charcole, 
    };
  },
  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? COLORS.primary : COLORS.white,
      textAlign: "left",
      color: isSelected ? COLORS.white : COLORS.charcole,
      cursor: isDisabled ? "not-allowed" : "default",
      boxShadow: "none",

      "&:hover": {
        backgroundColor: isSelected ? COLORS.primary : COLORS.lightGray,
      },
    };
  },
  menu: (base) => ({
    ...base,
    zIndex: 9999,
    boxShadow: "none",
    border: `1px solid ${COLORS.lightGray}`,
  }),
};

export const Dropdown = ({ errorMessage, error, label, ...rest }) => {
  return (
    <div className="flex w-full flex-col items-start my-3 relative">
      {/* <div
        className={clsx(
          "invisible bottom-10 left-2 text-textGray absolute px-2 bg-white z-10",
          !!rest.value &&
            rest.value !== null &&
            rest.isDisabled &&
            "bg-slate-200 rounded-lg"
        )}
        style={{
          visibility: !!rest.value && rest.value !== null && "visible",
        }}
      >
        {label}
      </div> */}
      <div className="pb-1 text-textGray font-glacial text-[18px]">{label}</div>
      <Select styles={customStyles} className="w-full font-glacial" {...rest} />
      {!!error && (
        <div className="text-sm text-left text-errorText pl-1 font-glacial">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
