import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: null,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearState: (state) => {
      state.userData = null;
      localStorage.clear();
      indexedDB.deleteDatabase("myDB");
      return state;
    },

    setUserData: (state, actions) => {
      state.userData = actions.payload;
      return state;
    },
  },
});

export const { clearState, setUserData } = userSlice.actions;
export default userSlice.reducer;
