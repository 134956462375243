import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import thunk from "redux-thunk";
import usersReducer from "./users/reducer";
import storage from "redux-persist-indexeddb-storage";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";

const persistConfig = {
  key: "root",
  storage: storage("myDB"),
};

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
});

const routeMiddleware = routerMiddleware(history);

const rootReducer = combineReducers({
  users: usersReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, routeMiddleware],
});

export const persistor = persistStore(store);
