export const BASEURL = process.env.REACT_APP_BASE_URL;

export const CAT_ID = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
  13: "thirteen",
  14: "fourteen",
  15: "fifteen",
};

export const APP_STEPS = [
  {
    name: "Download the App",
    desc: "Click the links above to download the app on the App Store or Google Play Store.",
  },
  {
    name: "Create a Password",
    desc: "Open the app, select sign in and enter the email you used to make your appointment. Verify your email and follow the prompts to create a password.",
  },
  {
    name: "Complete Your Profile",
    desc: "Complete your profile and insurance information before your first visit. This is required.",
  },
];

export const PREFERENCE_TYPE = {
  BY_TIME: "TIME",
  ALL: "ALL",
};

export const ENCRYPTKEY = process.env.REACT_APP_ENCRYPTKEY;
export const GOOGLEURL = process.env.REACT_APP_GOOGLE_PLAY_URL;
export const APPLEURL = process.env.REACT_APP_APPLE_URL;

export const INIT_FILTERS = {
  speciality: [],
  language: [],
  timezone: null,
};

export const INPERSON_VISIT_TYPE = "initial-in-person";

export const PRIMARY_SUBS_OPTIONS = [
  {
    value: "Self",
    label: "Self",
  },
  {
    value: "Dependent",
    label: "Dependent",
  },
  {
    value: "Spouse",
    label: "Spouse",
  },
];

export const ImageResolution = {
  HEIGHT: 100,
  WIDTH: 100,
};

export const SELF_PAY_INSURANCE_ID =
  process.env.REACT_APP_SELF_PAY_INSURANCE_ID;
