import React from "react";
import { CloseIcon } from "../../../icons";

export const Modal = ({ title, closeModal, children }) => {
  return (
    <>
      <div className="justify-center text-textGray items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white ">
            {/*header*/}
            {/*body*/}
            <div className="px-5 pb-10">{children}</div>
            {/*footer*/}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
