import clsx from "clsx";
import React, { useState } from "react";
import { CloseIcon, InfoIcon, Loader } from "../../../icons";
import { COLORS } from "../../../helper/colors";

export const Input = ({
  label,
  rightIcon,
  errorMessage,
  error,
  popUpMsg = "",
  haveCode = false,
  onHaveCode,
  codeLoading,
  children,
  ...rest
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  return (
    <div className="flex w-full flex-col items-start my-6">
      <div
        className={clsx(
          "flex w-full flex-1 items-center pb-0.5 pt-2 border-b border-textGray  focus-within:border-textGray relative group",
          rest.disabled && "bg-gray-50",
          !rest.disabled && "hover:border-textGray",
          haveCode && "justify-between"
        )}
      >
        <div
          className={clsx(
            "flex  justify-center items-center absolute bottom-9 text-[18px] left-0 bg-transparent text-textGray",
            !!rest.value &&
              rest.value !== "" &&
              rest.disabled &&
              "bg-slate-200 rounded-lg",
            isFocus ? "visible" : "invisible"
          )}
          style={{ visibility: !!rest.value && rest.value !== "" && "visible" }}
        >
          <div className="pr-1.5 font-glacial text-[18px]">{label}</div>
          {!!popUpMsg && <InfoIcon onClick={() => setIsPopUpOpen(true)} />}
        </div>
        {isPopUpOpen && (
          <div className="flex p-5 flex-row justify-center items-center bg-darkGray w-[240px] xm:w-[280px] bt:w-[320px] text-sm bt:text-base rounded-xl absolute bottom-16 left-0 xl:left-[112px] bt:left-[42px]">
            <div className="pr-2 text-white font-glacial">{popUpMsg}</div>
            <CloseIcon
              height={40}
              width={40}
              fill={COLORS.white}
              className="cursor-pointer"
              onClick={() => setIsPopUpOpen(false)}
            />
          </div>
        )}
        <input
          className={clsx(
            "w-full max-w-full border-none outline-none placeholder:font-glacial placeholder:text-[19px] focus:placeholder:invisible flex flex-1 text-[18px] text-textGray",
            rest.disabled && "bg-gray-50"
          )}
          placeholder={label}
          onFocus={() => setIsFocus(true)}
          {...rest}
        />{" "}
        {codeLoading && <Loader height={20} width={20} fill="#fff" />}
        {haveCode && (
          <div
            className="flex text-textGray font-glacial text-xs sm:text-[16px] tb:text-[12px] xl:text-[16px] underline underline-offset-2 cursor-pointer"
            onClick={onHaveCode}
          >
            Don't have a code?
          </div>
        )}
        {!!rightIcon && <div className="pl-1.5">{rightIcon}</div>}
      </div>
      {!!error && (
        <div className="text-sm pt-0.5 text-errorText font-glacial">
          {errorMessage}
        </div>
      )}
    </div>
    //   )}
    // </>
  );
};
